<template>
  <div>
    <div v-show="step === 1">
      <div v-if="isSend" class="verify-headline">
        <h3 class="login-headline font-semibold">
          {{ $t('login.loginInfo.verifyTitle') }}
        </h3>
        <div v-if="locale !== 'jp'" class="login-subtitle">
          {{ $t('login.loginInfo.verifyContent') }}
        </div>
        <div class="login-tip" :class="{ 'login-exchange': locale === 'jp' }">
          <span v-if="type === 'phone'">+{{ formData.phoneAreaCode.value }}</span>
          <span>{{ formData.phoneOrEmail }}</span>
        </div>
        <div v-if="locale === 'jp'" class="login-exchange-end">
          {{ $t('login.loginInfo.verifyContent') }}
        </div>
      </div>
      <van-form ref="loginForm" @submit="login">
        <PhoneOrEmailInput
          v-show="!isSend"
          v-model="formData.phoneOrEmail"
          :rules="currentRules.phoneOrEmail"
          :last-modified="lastModified"
          @areaChange="areaChange"
          @update-type="updateType"
        />
        <CodeInput
          v-show="isSend"
          ref="phoneOrEmailCode"
          v-model="formData.captcha"
          :scene="scene"
          :type="type"
          :is-top="isTop"
          :phone-or-email="formData.phoneOrEmail"
          :rules="currentRules.captcha"
          :area-code="formData.phoneAreaCode.value"
          :cookie-name="cookieName"
          :before-send="codeBeforeSend"
          :send-success="codeSendSuccess"
          :send-error="codeSendError"
        />
      </van-form>
      <!-- 可以切换 -->
      <div v-if="hasRepeat" class="has-repeat">
        {{ $t("login.login.ThisPhoneAlready") }}
        <span @click="toSignUp(3)">{{ $t("login.login.SignInNow") }}</span>
      </div>
      <div v-if="hasRepeat2" class="has-repeat">
        {{ $t("login.login.ItSeemsHaven") }}
        <span @click="toSignUp(2)">{{ $t("login.login.SignUpNow") }}</span>
      </div>
      <div v-if="isUnSignUp && isTop === 2" class="has-repeat">
        {{ $t('login.login.ThisPhoneAlready') }}
        <span @click="toSignLogin">{{ $t('login.login.SignInNow') }}</span>
      </div>
      <!-- 注册并登录 -->
      <div v-if="isUnSignUp && isTop === 3" class="has-repeat">
        {{ $t('login.login.ItSeemsHaven') }}
        <span @click="toSignLogin">{{ $t('login.login.SignUpNow') }}</span>
      </div>
      <div>
        <div v-if="!isSend" class="update-button-container">
          <van-button
            type="primary"
            class="button login-button new-login-font-bold"
            :text="$t('login.login.loginForm.continueBtn')"
            :disabled="!isButtonContinue"
            :loading="sendLoading"
            @click="goSendCode('phoneOrEmailCode')"
          />
          <div v-if="isTop === 3" />
          <!-- <div v-else class="update-divider">
            <div class="update-dividet-text">
              {{ $t("login.login.loginForm.changeTipText") }}
            </div>
          </div> -->
          <!-- <van-button
            class="button switch-button new-login-font"
            :text="$t('login.login.loginForm.switchEmail')"
            :disabled="sendLoading"
            @click="switchType('email')"
          >
            <i class="iconfont iconicon_email_normal" />
            {{ $t("login.login.loginForm.phone.switchBtn") }}
          </van-button> -->
        </div>
        <div
          v-if="isSend"
          class="update-button-container"
          style="padding-top: 40px"
        >
          <van-button
            type="primary"
            class="button login-button new-login-font-bold"
            :text="$t('login.login.loginForm.submitBtnText')"
            :loading="isLoging"
            :disabled="!isLoginSubmitAvalible"
            @click="loginSubmit"
          />
        </div>
      </div>
    </div>
    <template v-if="step === 2">
      <div>
        <div v-if="!isQueryAccountCodeSend" class="verify-headline">
          <h3 class="login-headline font-semibold">
            {{ this.$t('login.loginInfo.findRelatedAcount', {num: relateList.length}) }}
          </h3>
        </div>
        <div v-else class="verify-headline">
          <h3 class="login-headline font-semibold">
            {{ $t('login.loginInfo.verifyTitle') }}
          </h3>
          <div v-if="locale !== 'jp'" class="login-subtitle">
            {{ $t('login.loginInfo.verifyContent') }}
          </div>
          <div class="login-tip" :class="{ 'login-exchange': locale === 'jp' }">
            <span v-if="selectedAccountType === 'phone'">+{{ selectedAccountInfo.countryCallingCode }}</span>
            <span>{{ selectedAccountType === 'email' ? selectedAccountInfo?.desensitizedEmail : selectedAccountInfo?.desensitizedPhone }}</span>
          </div>
          <div v-if="locale === 'jp'" class="login-exchange-end">
            {{ $t('login.loginInfo.verifyContent') }}
          </div>
        </div>
        <div v-if="!isQueryAccountCodeSend">
          <div class="exist-account-body">
            <div
              v-for="(item, index) in relateList"
              :key="index"
              :class="['exist-account-item', { 'is-active': selectedIndex === index }]"
              @click="selectItem(index)"
            >
              <div class="item-body">
                <span class="text">{{ loginConfig.existAccount }}</span>
                <span class="account">{{ item.desensitizedPhone || item.desensitizedEmail }}</span>
              </div>
              <img v-if="selectedIndex === index" class="icon" v-lazy-load data-src="~assets/touch/images/login/icon-selected.png" alt="">
            </div>
          </div>
          <div class="update-button-container">
            <van-button
              type="primary"
              class="button login-button new-login-font"
              :text="$t('login.login.loginForm.submitBtnText')"
              :loading="accountSendLoading"
              @click="existAccountGoSendCode"
            />
          </div>
          <div class="create-operation" @click="createLogin">
            {{ $t('login.login.CreateANewAccount') }}
          </div>
        </div>
        <!-- 查询手机号发送验证码 -->
        <van-form
          v-show="isQueryAccountCodeSend"
          ref="queryAccountForm"
        >
          <CodeInput
            ref="accountCode"
            v-model="queryAccountData.captcha"
            :scene="scene"
            :type="selectedAccountType"
            :rules="currentRules.captcha"
            :phone-or-email="!selectedAccountInfo?.phone ? selectedAccountInfo?.email : selectedAccountInfo?.phone"
            :area-code="!selectedAccountInfo?.phone ? '' : selectedAccountInfo?.countryCallingCode"
            :cookie-name="cookieName"
            :send-success="queryAccountCodeSendSuccess"
            :send-error="queryAccountCodeSendError"
          />
        </van-form>
        <div v-if="isQueryAccountCodeSend" class="update-button-container">
          <van-button
            type="primary"
            class="button login-button new-login-font"
            :text="$t('login.login.loginForm.submitBtnText')"
            :loading="isLoging"
            :disabled="!isAccountLoginSubmitAvalible"
            @click="existAccountLogin"
          />
        </div>
      </div>
    </template>
    <template v-if="step === 3">
      <div class="bind-account-body">
        <div class="avatar">
          <img class="img" v-lazy-load :data-src="selectedAccountInfo.avatar" alt="">
        </div>
        <div class="title">
          {{ loginConfig.updateExistAccount }}
        </div>
        <div class="desc">
          {{ loginConfig.updateRegisInfoExistAccount }}
        </div>
        <div class="operation">
          <div class="bind-btn left" @click="originAccountLogin">
            {{ loginConfig.no }}
          </div>
          <div class="bind-btn right" @click="undateWithCode">
            {{ loginConfig.yes }}
          </div>
        </div>
      </div>
    </template>
    <template v-if="step === 4">
      <div class="update-container">
        <div class="success-body">
          <div class="header">
            <i class="iconfont icon-selected icon" />
            <div class="text">
              {{ loginConfig.success }}
            </div>
          </div>
          <div class="info">
            <div class="phone">
              {{ loginConfig.mobile }}: {{ type == 'phone' ? formData.phoneOrEmail : selectedAccountInfo.phone }}
            </div>
            <div class="email">
              {{ loginConfig.email }}: {{ type == 'email' ? formData.phoneOrEmail : selectedAccountInfo.email }}
            </div>
          </div>
        </div>
        <div class="update-button-container">
          <van-button
            type="primary"
            class="button login-button new-login-font"
            :text="$t('login.login.loginForm.submitBtnText')"
            :loading="isLoging"
            @click="originAccountLogin"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import PhoneOrEmailInput from 'components/login/newLoginForm/PhoneOrEmailInput.vue';
import CodeInput from 'components/login/newLoginForm/newPhoneOrEmailCodeInput.vue';

export default {
  components: { PhoneOrEmailInput, CodeInput },
  props: {
    isLoging: {
      // 登陆按钮加载中
      type: Boolean,
      default: false,
    },
    // canSwitchType: {
    //   type: Boolean,
    //   default: true,
    // },
    cookieName: {
      type: String,
      default: 'login-phone-count',
    },
    scene: {
      type: Number,
      default: 1,
    },
    step: {
      type: Number,
      default: 1,
    },
    lastModified: {
      type: Object,
      default: () => ({}),
    },
    isTop: {
      type: Number,
      default: 2,
    },
    policyAgreeStatus: {
      type: Boolean,
      default: true,
    },
    isUnSignUp: {
      type: Boolean,
      default: false,
    },
    // 关联账号列表
    relateList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const loginConfig = this.$t('login.loginInfo')
    return {
      loginConfig,
      isChecking: false, // 检测中
      contact:
        this.$t('common.contactPhone') !== ''
          ? this.$t('common.contactPhone')
          : this.$t('common.contactEmail'),
      isSend: false, // 是否切换到code页面
      isQueryAccountCodeSend: false, // 是否查询账户发送验证码
      sendLoading: false, // 按钮加载中状态
      accountSendLoading: false, // 查询账户发送验证码加载中
      formData: {
        phoneOrEmail: '',
        captcha: '',
        phoneAreaCode: {
          value: '',
        },
      },
      type: 'email',
      phoneLoginRule: {
        phoneOrEmail: [
          {
            required: true,
            message: this.$t('login.login.loginForm.phone.rules.required'),
            trigger: 'onBlur',
            formatter: (value) => value.trim(),
          },
        ],
        captcha: [
          {
            required: true,
            message: this.$t('login.login.loginForm.captcha.rules.required'),
            trigger: 'onBlur',
            formatter: (value) => value.trim(),
          },
          {
            pattern: /^\d{6}$/,
            message: this.$t('login.login.loginForm.captcha.rules.format'),
            trigger: 'onBlur',
            formatter: (value) => value.trim(),
          },
        ],
      },
      emailLoginRule: {
        phoneOrEmail: [
          {
            required: true,
            message: this.$t('login.login.loginForm.email.rules.required'),
            trigger: 'onBlur',
            formatter: (value) => value.trim(),
          },
          {
            pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,8})$/,
            message: this.$t('login.login.loginForm.email.rules.format'),
            trigger: 'onBlur',
            formatter: (value) => value.trim(),
          },
        ],
        captcha: [
          {
            required: true,
            message: this.$t('login.login.loginForm.captcha.rules.required'),
            trigger: 'onBlur',
            formatter: (value) => value.trim(),
          },
          {
            pattern: /^\d{6}$/,
            message: this.$t('login.login.loginForm.captcha.rules.format'),
            trigger: 'onBlur',
            formatter: (value) => value.trim(),
          },
        ],
      },
      hasRepeat: false,
      hasRepeat2: false,
      selectedIndex: 0,
      legalCount: 1,
      queryAccountData: {
        captcha: '',
        phoneAreaCode: {
          value: '',
        },
      },
    };
  },
  computed: {
    isLoginSubmitAvalible() {
      return /^\d{6}$/.test(this.formData.captcha);
    },
    isAccountLoginSubmitAvalible() {
      return /^\d{6}$/.test(this.queryAccountData.captcha);
    },
    isPhoneContinue() {
      if (this.formData.phoneAreaCode.reg) {
        return this.formData.phoneAreaCode.reg.test(this.formData.phoneOrEmail);
      }
      return this.formData.phoneOrEmail.trim() !== '';
    },
    isEmailContinue() {
      const isContinue = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,8})$/.test(
        this.formData.phoneOrEmail,
      );
      return isContinue;
    },
    isButtonContinue() {
      const isContinue = this.type === 'email' ? this.isEmailContinue : this.isPhoneContinue;
      return isContinue;
    },
    isOrgSchool() {
      return this.locale === 'am' || this.locale === 'ae';
    },
    locale() {
      return this.$store.state.locale;
    },
    currentRules() {
      return this.type === 'email' ? this.emailLoginRule : this.phoneLoginRule;
    },
    // 选中的账号信息
    selectedAccountInfo() {
      return this.relateList[this.selectedIndex];
    },
    selectedAccountType() {
      if (this.selectedAccountInfo?.phone) {
        return 'phone';
      }
      return 'email';
    },
  },
  watch: {
    step() {
      this.accountSendLoading = false;
    },
  },
  methods: {
    init() {
      this.$refs.phoneOrEmailCode.init();
      this.formData.captcha = '';
      this.isSend = false;
    },
    /**
     * 触发登录按钮
     */
    loginSubmit() {
      this.$emit('changeType', this.type);
      this.$nextTick(() => {
        this.$refs.loginForm.submit();
      })
    },
    login() {
      this.$emit('submit', this.formData);
    },
    updateType(type) {
      this.type = type;
    },
    areaChange(obj) {
      this.formData.phoneAreaCode = obj;
      this.setPhonePattern();
      if (this.formData.phoneOrEmail === '') return;
      this.$refs.loginForm.validate('phoneOrEmail');
    },
    // 触发发送验证码
    goSendCode(codInputName) {
      if (!this.policyAgreeStatus && !this.isOrgSchool) {
        this.legalCount += 1;
        this.$emit('changeLegalCount');
        return;
      }
      if (this.sendLoading || !this.isButtonContinue) {
        return;
      }
      this.sendLoading = true;
      this.$refs[codInputName].sendCodeHanlder()
    },
    // 设置手机号正则校验格式
    setPhonePattern() {
      const { reg } = this.formData.phoneAreaCode;
      this.phoneLoginRule.phoneOrEmail[1] = {
        pattern: reg,
        message: this.$t('login.login.loginForm.phone.rules.format'),
        trigger: 'onBlur',
        formatter: (value) => value.trim(),
      };
    },
    // 验证码发送前
    codeBeforeSend(callback) {
      callback();
    },
    // 发送成功
    codeSendSuccess() {
      this.sendLoading = false;
      this.isSend = true;
      let valueCode = '';
      // 修改dialog文案使用
      valueCode = `+${this.formData.phoneAreaCode.value} ${this.formData.phoneOrEmail}`;
      this.$emit('titleChange', {
        isSend: this.isSend,
        valueCode,
      });
    },
    queryAccountCodeSendSuccess() {
      this.accountSendLoading = false;
      this.isQueryAccountCodeSend = true;
      this.$emit('changeAccountSend', this.isQueryAccountCodeSend);
    },
    backAccountCodeSend() {
      this.isQueryAccountCodeSend = false;
      this.$emit('changeAccountSend', this.isQueryAccountCodeSend);
    },
    codeSendError() {
      this.sendLoading = false;
    },
    queryAccountCodeSendError() {
      this.accountSendLoading = false;
    },
    toSignUp(num) {
      this.hasRepeat = false;
      this.hasRepeat2 = false;
      this.$emit('toSignIn', num);
    },
    toSignLogin() {
      this.$emit('toSignLogin');
    },
    existAccountGoSendCode() {
      this.accountSendLoading = true
      console.log('existAccountSubmit', this.selectedAccountInfo);
      if (!this.selectedAccountInfo.phone && !this.selectedAccountInfo.email) {
        this.$Toast('请选择账号');
        this.accountSendLoading = false;
        return;
      }
      this.$refs.accountCode.sendCodeHanlder();
    },
    prepareLoginData() {
      const data = {
        phoneAreaCode: {
          value: '',
        },
        captcha: this.queryAccountData.captcha,
        selectedAccountType: this.selectedAccountType,
      };

      if (this.selectedAccountType === 'phone') {
        data.phoneOrEmail = this.selectedAccountInfo.phone;
        data.phoneAreaCode.value = this.selectedAccountInfo.countryCallingCode;
      } else {
        delete data.phoneAreaCode;
        data.phoneOrEmail = this.selectedAccountInfo.email;
      }

      return data;
    },
    existAccountLogin() {
      const data = this.prepareLoginData();
      data.originAccountIsempty = this.type !== this.selectedAccountType;
      // 如果原账号信息不为空
      if (this.selectedAccountInfo.phone && this.selectedAccountInfo.email) {
        data.originAccountIsempty = false;
      }
      this.$emit('changeType', this.selectedAccountType);
      this.$nextTick(() => {
        this.$emit('submit', data, true);
      });
    },
    originAccountLogin() {
      const data = this.prepareLoginData();
      this.$emit('changeType', this.selectedAccountType);
      this.$nextTick(() => {
        this.$emit('toCreateLogin', data);
      });
    },
    undateWithCode() {
      this.$emit('changeType', this.type);
      // 预校验选择信息
      const preData = this.prepareLoginData();
      // 用户输入信息 fromData
      const data = {
        preData,
        formData: this.formData,
      }
      this.$emit('updateRegisterInfo', data);
    },
    createLogin() {
      this.$emit('changeType', this.type);
      this.$emit('toCreateLogin', this.formData);
    },
    selectItem(index) {
      this.selectedIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.arrow-button {
  width: px2vw(96);
  height: px2vw(96);
  background: var(--txt-color-link);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto px2vw(32);
  cursor: pointer;

  &:hover {
    background: var(--txt-color-link2);
  }

  .arrow-img {
    background: url("~assets/images/common/next-arrow.png") no-repeat;
    background-size: 100%;
    width: px2vw(40);
    height: px2vw(40);
  }
}

.arrow-disabled {
  opacity: 0.2;
}

.tip {
  font-size: px2vw(24);
  color: var(--txt-color-lv2);
  line-height: px2vw(28);
  text-align: center;
  margin-bottom: px2vw(40);
}
.update-button-container {
  padding-top: 16px;
}
.has-repeat {
  font-size: 12px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #d44e4e;
  line-height: 15px;
  margin-top: 10px;
  span {
    color: var(--txt-color-link);
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: var(--txt-color-link2);
    }
  }
}
.sign-up-n {
  font-size: 12px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: var(--txt-color-lv2);
  line-height: 18px;
  margin-top: 20px;
  cursor: pointer;
  span {
    color: var(--txt-color-link);
    text-decoration: underline;
    &:hover {
      color: var(--txt-color-link2);
    }
  }
}
.exist-account-body {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 20px 0 40px 0;

  .exist-account-item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    word-break: break-word;
    flex: 1 1 calc(50% - 8px);
    position: relative;
    cursor: pointer;
    height: 104px;
    padding: 12px 5px;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.05);
    border-radius: 8px;
    border: 2px solid #fff;
    &.is-active {
      background: #FFF9EC;
      border: 2px solid var(--txt-color-link);
    }
    .item-body {
      display: flex;
      flex-direction: column;
    }
    .text {
      display: inline-block;
      margin-bottom: 16px;
      font-size: 14px;
      font-family: 'Montserrat-Medium', 'Montserrat', sans-serif;
      font-weight: 500;
      color: #222222;
      line-height: 18px;
    }
    .account {
      font-size: 20px;
      font-family: 'Montserrat-Bold', 'Montserrat', sans-serif;
      font-weight: bold;
      color: #FF9F0A;
      line-height: 24px;
    }
    .icon {
      position: absolute;
      width: 24px;
      height: 24px;
      bottom: -2px;
      right: -1px;
      color: var(--txt-color-link);
    }
  }
}
.create-operation {
  cursor: pointer;
  text-align: center;
  margin-top: 22px;
  font-size: 16px;
  font-family: 'Montserrat-Bold', 'Montserrat', sans-serif;
  font-weight: bold;
  color: rgba(34, 34, 34, 0.6);
  line-height: 16px;
}
.bind-account-body {
  text-align: center;
  .avatar {
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
    .img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .title {
    font-size: 20px;
    font-family: 'Montserrat-Bold', 'Montserrat', sans-serif;
    font-weight: bold;
    color: #222222;
    line-height: 24px;
  }
  .desc {
    margin-top: 10px;
    font-size: 14px;
    font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
    font-weight: 400;
    color: #7A7A7A;
    line-height: 18px;
  }
  .operation {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
    .bind-btn {
      text-align: center;
      cursor: pointer;
      width: 160px;
      height: 50px;
      border-radius: 40px;
      border: 1px solid #E6E6E6;
      font-size: 16px;
      font-family: 'Montserrat-Bold', 'Montserrat', sans-serif;
      font-weight: bold;
      line-height: 50px;
    }
    .left {
      background: #fff;
      color: rgba(34, 34, 34, 0.6);
    }
    .right {
      border: 1px solid var(--txt-color-link);
      background: var(--txt-color-link);
      color: #ffffff;
    }
  }
}
.update-container {
  .success-body {
    background: #FFF9EC;
    border-radius: 8px;
    margin-bottom: 4px;
    padding: 30px 16px 16px;
    .header {
      text-align: center;
      .icon {
        font-size: 70px;
        color: var(--txt-color-link);
      }
      .text {
        margin: 5px 0 30px;
        font-size: 18px;
        font-family: 'Montserrat-Bold', 'Montserrat', sans-serif;
        font-weight: bold;
        color: var(--txt-color-link);
        line-height: 20px;
      }
    }
    .info {
      background: #FFFFFF;
      border-radius: 8px;
      padding: 16px;
      .phone,
      .email {
        font-size: 14px;
        font-family: 'Montserrat-Medium', 'Montserrat', sans-serif;
        font-weight: 500;
        color: rgba(34, 34, 34, 0.7);
        line-height: 18px;
      }
      .phone {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
